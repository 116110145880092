import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log("Policy form Stimulus controller connected");
  }

  submitForm() {
    this.formTarget.submit();
  }
}