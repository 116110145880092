import { Controller } from "@hotwired/stimulus";

// https://stackoverflow.com/questions/10473994/javascript-adding-decimal-numbers-issue
const roundNumber = (number, decimals) => parseFloat(new Number(number+'').toFixed(parseInt(decimals)))

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export default class extends Controller {
  static targets = [ "hiddenInput", "total", "years", "plan", "addons", "planTypeInput", "hiddenQuantityInput", "display", "planLengthInput" ]

  connect() {
    this.calculate()
  }
  
  calculate() {
    const years = this.findCurrentYearsSelected()
    const planValue = this.findCurrentSelectedPlanValue()
    const totalPlanValue = years * planValue
    const plusMinusValues = this.plusMinusValues(years)
    const checkboxValues = this.checkboxValues(years)
    const addOnsTotal = roundNumber(plusMinusValues + checkboxValues, 12) 
    const grandTotal = roundNumber(totalPlanValue + addOnsTotal, 12)
    
    this.planTarget.textContent = `${USDollar.format(totalPlanValue)}`
    this.yearsTarget.textContent = `${years}`
    this.addonsTarget.textContent = `${USDollar.format(addOnsTotal)}`
    this.totalTarget.textContent = `${USDollar.format(grandTotal)}`
  }
  
  findCurrentYearsSelected() {
    return this.planLengthInputTargets.find((element) => element.checked).value
  }
  
  findCurrentSelectedPlanValue() {
    return parseFloat(this.planTypeInputTargets.find((element) => element.checked).dataset.price)
  }
  
  plusMinusValues(years) {
    return this.hiddenQuantityInputTargets.reduce((accumulator, element) => 
      accumulator + parseFloat(element.dataset.unitPrice) * element.value,
      0.0
    ) * years
  }
  
  checkboxValues(years) {
    return this.hiddenInputTargets.reduce((accumulator, element) => 
      element.checked ? accumulator + parseFloat(element.dataset.unitPrice) : accumulator,
      0.0
    ) * years
  }

  handleQuantityChange(event) {
    const key = event.params.key;
    const operation = event.params.operation;
    const hiddenInput = this.hiddenQuantityInputTargets.find(input => input.dataset.key === key);
    const display = this.displayTargets.find(span => span.dataset.policyRequestTotalsKeyParam === key);

    if (hiddenInput && display) {
      let currentValue = parseInt(hiddenInput.value, 10) || 0;
      if (operation === "plus" && currentValue < 3) {
        currentValue++;
      } else if (operation === "minus" && currentValue > 0) {
        currentValue--;
      }
      hiddenInput.value = currentValue;
      display.textContent = currentValue;
    }
    this.calculate()
  }

  openModal() {
    document.querySelector('#modal').classList.remove('hidden');
    document.body.style.overflow = 'hidden';
  }
  
  closeModal() {
    document.querySelector('#modal').classList.add('hidden');
    document.body.style.overflow = 'auto';
  }
}